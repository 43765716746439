import React, { useCallback, useMemo, useState } from 'react';
import { FaBarcode, FaFileExcel } from 'react-icons/fa';
import { Spinner } from 'reactstrap';
import qs from 'qs';

import { DayOnlinity } from './DayOnlinity';
import { DayPackRow, OnlinityRow } from './MachineTypes';
import { ErrorAlert } from '../components/ErrorAlert';
import { HourPackModal } from './HourPackModal';
import { hourStr } from '../utils/format';
import { useAPI } from '../services/api';

interface DayPacksProps {
  bags?: boolean;
  date: Date;
  machineId: number;
  suspicious?: boolean;
  turns?: boolean;
}

export const DayPacks: React.FC<DayPacksProps> = ({
  bags,
  date,
  machineId,
  suspicious,
  turns,
}) => {
  const { data, refreshing, error } = useAPI<{
    data: DayPackRow[];
    onlinity: OnlinityRow[];
  }>(
    `/my-machines/${machineId}/packs?` +
      qs.stringify({
        date: date.toJSON(),
        turns: turns ? 1 : undefined,
        bags: bags ? 1 : undefined,
        suspicious: suspicious ? 1 : undefined,
      })
  );

  const [hour, setHour] = useState<Date | undefined>(undefined);

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      const hour = parseInt(e.currentTarget.getAttribute('data-hour') || '');
      const hourDate = new Date(date.getTime());
      hourDate.setHours(hour, 0, 0, 0);
      setHour(hourDate);
    },
    [date]
  );

  const totals = useMemo(
    () =>
      (data?.data || []).reduce(
        (res, row) => ({
          suspicious: res.suspicious + row.suspicious,
          heavy: res.heavy + row.heavy,
          cnt: res.cnt + row.cnt,
          total_scans: res.total_scans + row.total_scans,
          master_scans: res.master_scans + row.master_scans,
          hr: 0,
        }),
        {
          suspicious: 0,
          heavy: 0,
          cnt: 0,
          total_scans: 0,
          master_scans: 0,
          hr: 0,
        } as DayPackRow
      ),
    [data]
  );

  return (
    <>
      {refreshing && (
        <div className="mb-3 text-center">
          <Spinner color="secondary" />
        </div>
      )}
      <ErrorAlert className="mt-3">{error}</ErrorAlert>

      {data && hour && (
        <HourPackModal
          date={hour}
          hours={data.data.map((i) => i.hr)}
          machineId={machineId}
          onDateChange={setHour}
          suspicious={suspicious}
        />
      )}

      {data &&
        !refreshing &&
        (data.data.length > 0 ? (
          <>
            <table className="table table-sm table-hover tab-nums mb-3">
              <thead>
                <tr>
                  <th>Интервал</th>
                  <th>Упаковок</th>
                  <th>До 23 кг</th>
                  <th>От 23 кг</th>
                  {suspicious && <th>Подозр.</th>}
                  <th>
                    <FaBarcode />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((i) => (
                  <tr key={i.hr} data-hour={i.hr} onClick={onRowClick}>
                    <td className="text-primary">{hourStr(i.hr)}</td>
                    <td>{i.cnt || ''}</td>
                    <td>{i.cnt - i.heavy || ''}</td>
                    <td>{i.heavy || ''}</td>
                    {suspicious && <td>{i.suspicious || ''}</td>}
                    <td>{i.total_scans || ''}</td>
                    <td className="text-danger">{i.master_scans || ''}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Всего</th>
                  <th>{totals.cnt || ''}</th>
                  <th>{totals.cnt - totals.heavy || ''}</th>
                  <th>{totals.heavy || ''}</th>
                  {suspicious && <th>{totals.suspicious || ''}</th>}
                  <th>{totals.total_scans || ''}</th>
                  <th className="text-danger">{totals.master_scans || ''}</th>
                </tr>
              </tfoot>
            </table>
            <div className="my-3">
              <a
                className="btn btn-secondary btn-sm"
                href={`/api/my-machines/${machineId}/export?date=${date.toJSON()}`}
              >
                <FaFileExcel /> Экспорт в Excel
              </a>
            </div>
            <DayOnlinity day={date} intervals={data.onlinity} />
          </>
        ) : (
          <div className="mb-3">Нет упаковок за выбранный день</div>
        ))}
    </>
  );
};
