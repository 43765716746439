import { History, Location } from 'history';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { toastError } from '../utils/toastError';
import { api } from './api';

const ID_CREATING = 'create';

interface UseItemOptions<T> {
  initialValues?: T;
  routePath?: string;
  location?: Location;
}

interface ItemWithId {
  id?: number;
}

export interface SaveResult {
  FORM_ERROR?: string;
}

export interface UseItemResult<T> {
  creating: boolean;
  error?: string;
  found: boolean;
  hasData: boolean;
  item?: T;
  notFound: boolean;
  refresh: () => void;
  refreshing: boolean;
  remove: (options?: { redirectTo?: string }) => Promise<void>;
  save: (values: T) => Promise<SaveResult | undefined>;
}

export const useItem = <T extends ItemWithId>(
  id: number | string,
  path: string,
  history: History,
  options?: UseItemOptions<T>
): UseItemResult<T> => {
  const creating = id === ID_CREATING;
  const [item, setItem] = useState<T | undefined>(
    creating ? (options && options.initialValues) || ({} as T) : undefined
  );
  const [refreshing, setRefreshing] = useState<boolean>(!creating);
  const [error, setError] = useState<string | undefined>(undefined);
  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const refresh = useCallback(() => {
    setError(undefined);
    if (!creating) {
      setRefreshing(true);
      api
        .get(`${path}/${id}`)
        .then((res) => {
          if (!unmounted.current) {
            setItem(res.data);
          }
        })
        .catch((err) => {
          if (!unmounted.current) {
            setError(err.message);
          }
        })
        .then(() => {
          if (!unmounted.current) {
            setRefreshing(false);
          }
        });
    }
  }, [creating, id, path]);

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  const save = useCallback(
    async (values: T) => {
      setError(undefined);
      try {
        const res = await (creating
          ? api.post(path, values)
          : api.put(`${path}/${id}`, values));

        toast.success('Сохранено');
        if (`${res.data.id}` !== id) {
          history.push(
            `${(options && options.routePath) || path}/${res.data.id}${
              options?.location?.search || ''
            }`
          );
        } else {
          setItem(res.data);
        }
        return undefined;
      } catch (err) {
        toastError(err);
      }
    },
    [creating, history, id, options, path]
  );

  const remove = useCallback(
    async ({ redirectTo }: { redirectTo?: string } = {}) => {
      setError(undefined);
      try {
        await api.delete(`${path}/${id}`);
        toast.success('Удалено');
        history.push(redirectTo || path);
      } catch (err) {
        toastError(err);
      }
    },
    [history, id, path]
  );

  const hasData = !refreshing && !error;

  return {
    creating,
    error,
    found: hasData && (creating || (item && item.id)) ? true : false,
    hasData,
    item,
    notFound: hasData && !creating && (!item || !item.id) ? true : false,
    refresh,
    refreshing,
    remove,
    save,
  };
};
