import React from 'react';

interface ErrorAlertProps {
  padded?: boolean;
}

export const ErrorAlert: React.FC<
  ErrorAlertProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({ children, className, padded = false, ...props }) => {
  if (!children) {
    return null;
  }

  let classNames = 'alert alert-danger';
  if (padded) {
    classNames += ' mt-3 mb-3';
  }
  if (className) {
    classNames += ' ' + className;
  }

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
