import React, { useMemo } from 'react';
import { range } from 'lodash';
import classnames from 'classnames';
import moment, { Moment } from 'moment';

import './DayOnlinity.css';
import { OnlinityRow } from './MachineTypes';

const isNow = (d: Moment) => {
  const diff = new Date().getTime() - d.toDate().getTime();
  return Math.abs(diff) < 120000;
};

interface DayOnlinityProps {
  day: Date;
  intervals: OnlinityRow[];
}

export const DayOnlinity: React.FC<DayOnlinityProps> = ({ day, intervals }) => {
  const fromMidnight = useMemo(() => {
    const m = new Date(day.getTime());
    m.setHours(0, 0, 0, 0);
    return (d: Moment) =>
      ((d.toDate().getTime() - m.getTime()) / 86400000) * 100;
  }, [day]);

  return (
    <div className="onlinity">
      <div className="interval-container">
        {moment().isSame(day, 'day') && (
          <div className="now" style={{ left: `${fromMidnight(moment())}%` }} />
        )}
        {intervals.map((i) => (
          <div
            key={i.id}
            className={classnames('interval', isNow(i.date_till) && 'till-now')}
            style={{
              left: `${fromMidnight(i.date_from)}%`,
              right: `${100 - fromMidnight(i.date_till)}%`,
            }}
          />
        ))}
      </div>
      <div className="hours clearfix">
        {range(0, 24).map((h) => (
          <div key={h} className="hours-hour">
            {h}
          </div>
        ))}
        <div className="last-tick">24</div>
      </div>
    </div>
  );
};
