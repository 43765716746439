import { FieldMetaState } from 'react-final-form';

export const REQUIRED = 'REQUIRED';

export const inputError = (meta: FieldMetaState<any>) => {
  const invalid = (meta.error || meta.submitError) && meta.submitFailed;
  return {
    invalid,
    error: meta.error === REQUIRED ? undefined : meta.error || meta.submitError,
  };
};
