import React, { useCallback, useEffect, useMemo } from 'react';
import { FaCheck, FaChevronLeft, FaTimes } from 'react-icons/fa';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';
import { Spinner } from 'reactstrap';

import { CenterSpinner } from '../components/CenterSpinner';
import { Datepicker } from './Datepicker';
import { DayPacks } from './DayPacks';
import { ErrorAlert } from '../components/ErrorAlert';
import { MyMachine as MyMachineType } from './MachineTypes';
import { useAPI } from '../services/api';
import { useSearch } from '../services/useSearch';
import { PackFilters } from './PackFilters';
import { Period } from './Period';

export const MyMachine: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  location,
  match,
}) => {
  const { data, error, refresh, refreshing } = useAPI<MyMachineType>(
    `/my-machines/${match.params.id}`
  );

  useEffect(() => {
    const t = setInterval(refresh, 5000);
    return () => clearInterval(t);
  }, [refresh]);

  const [search, setSearch] = useSearch({ history, location });

  const day = useMemo(() => {
    if (typeof search.date === 'string') {
      return new Date(search.date);
    }
    return new Date();
  }, [search]);

  const setDay = useCallback(
    (v: Moment) => {
      setSearch((s) => ({
        ...s,
        date: v.format('YYYY-MM-DD'),
      }));
    },
    [setSearch]
  );

  return (
    <>
      <div className="d-flex flex-row">
        <Link to="/" className="btn btn-sm btn-secondary">
          <FaChevronLeft /> к списку
        </Link>
        {data?.hasSettings && (
          <Link
            to={`/machine${match.params.id}/settings`}
            className="btn btn-sm btn-secondary ml-2"
          >
            Настройки
          </Link>
        )}
      </div>

      {!data && refreshing && <CenterSpinner />}

      <div className="row" style={{ marginBottom: 100 }}>
        <div className="col-sm-3">
          <ErrorAlert className="mt-3">{error}</ErrorAlert>
          {data && (
            <>
              <div className="d-flex flex-row align-items-center">
                <h1 className="mt-3">{data.serial}</h1>
                {data && refreshing && (
                  <Spinner color="secondary" size="sm" className="ml-2" />
                )}
              </div>
              <div>Модель: {data.model}</div>
              <div>
                Дата установки:{' '}
                {data.installedAt && data.installedAt.format('DD.MM.YYYY')}
              </div>
              <div>Место: {data.location}</div>
              <div>Город: {data.city}</div>

              <div
                className="my-4"
                title={
                  data.online.date
                    ? data.online.date.format('DD.MM.YYYY HH:mm:ss')
                    : undefined
                }
              >
                Подключение:{' '}
                {data.online.isActive ? (
                  <FaCheck className="text-success" />
                ) : (
                  <FaTimes className="text-danger" />
                )}
              </div>

              <div>
                <strong>Упаковки</strong>
              </div>
              <div>Сегодня: {data.packs.today}</div>
              <div>Всего: {data.packs.total}</div>
            </>
          )}
        </div>

        <div className="col-sm-6">
          {data && (
            <DayPacks
              bags={search.bags === '1'}
              date={day}
              machineId={data.id}
              suspicious={search.suspicious === '1'}
              turns={search.turns === '1'}
            />
          )}
        </div>
        <div className="col-sm-3">
          <Datepicker onChange={setDay} value={day} />
          <PackFilters search={search} setSearch={setSearch} />
          {data && <Period machineId={data.id} />}
        </div>
      </div>
    </>
  );
};
