import { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import moment from 'moment';

const reDate = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\.\d{3}Z$)?/;

export const dateReviver = (key: string, value: any): any => {
  if (typeof value === 'string' && value.length >= 10) {
    if (value.match(reDate)) {
      return moment(value);
    }
  }
  return value;
};

export const api = axios.create({
  baseURL: '/api',
  transformResponse: (data: any, opts: any) => {
    if (
      opts['content-type'] &&
      opts['content-type'].indexOf('application/json') === 0
    ) {
      return JSON.parse(data, dateReviver);
    }
    return data;
  },
});

export const useAPI = <T,>(url?: string, params?: object) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [refreshing, setRefreshing] = useState(0);

  const refresh = useCallback(() => {
    if (!url) {
      setData(undefined);
      return;
    }
    setRefreshing((v) => v + 1);
    setError(undefined);
    api
      .get<T>(url, { params })
      .then((res) => setData(res.data))
      .catch((err) => setError(err.message))
      .then(() => setRefreshing((v) => v - 1));
  }, [url, params]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data,
    setData,
    error,
    hasData: !refreshing && !error,
    refresh,
    refreshing: refreshing > 0,
  };
};

export const catchToast = (err: AxiosError) => {
  toast.error(err.message);
};
