import { Moment } from 'moment';

export interface Machine {
  id: number;
  deleted: number;
  date_created: Moment;
  date_edited: Moment;
  creator_id: number;
  editor_id: number;
  serial: string;
  model_id: number;
  title: string;
  location: string;
  city_id: number;
  owner_id: number;
  date_last_active: Moment | null;
  software_password: string | null;
  enable_updates: number;
  date_status: Moment | null;
  status_data: string | null;
  date_installed: Moment | null;
  hidden: number;
  notes: string | null;
  api_token: string | null;
  pack_cost: number | null;
  master_codes: string | null;
  code_salt: string | null;
  internal_ip: string | null;
  external_ip: string | null;
  software_version: string | null;
  heavy_bag_weight: number | null;
  password: string | null;
  password_dirty: number;
  password_enabled: number;
  payment_provider_id: number | null;
}

export interface MachineListItem {
  id: number;
  model: string;
  city: string;
  location: string;
  owner: string;
  editor: string;
  date_edited: Moment;
  hidden: boolean;
}

export const PaymentProviders = {
  1: 'Автокасса',
  2: 'ОФД',
};
