import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { useAPI } from '../services/api';
import { ErrorAlert } from '../components/ErrorAlert';
import { Spinner } from 'reactstrap';

interface PeriodProps {
  machineId: number;
}

export const Period: React.FC<PeriodProps> = ({ machineId }) => {
  const [dates, setDates] = useState({
    from: moment().subtract(6, 'day').startOf('day'),
    till: moment().endOf('day'),
  });

  const { data, refreshing, error } = useAPI<number>(
    `/my-machines/${machineId}/period?from=${dates.from.toJSON()}&till=${dates.till.toJSON()}`
  );

  const [values, setValues] = useState({
    from: dates.from.format('DD.MM.YYYY'),
    till: dates.till.format('DD.MM.YYYY'),
  });

  const onChange = useCallback(
    (name: 'from' | 'till') => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => ({ ...v, [name]: e.target.value }));
    },
    []
  );

  const onBlur = useCallback(
    (name: 'from' | 'till') => () => {
      setValues((v) => {
        let m = moment(v[name], 'DD.MM.YYYY');
        if (!m.isValid()) {
          m = moment();
        }
        m[name === 'from' ? 'startOf' : 'endOf']('day');
        setDates((d) => ({
          ...d,
          [name]: m,
        }));
        return { ...v, [name]: m.format('DD.MM.YYYY') };
      });
    },
    []
  );

  return (
    <div className="mt-4">
      <div className="mb-1">
        <strong>
          За период: {refreshing && <Spinner size="sm" color="secondary" />}
          {data}
        </strong>
      </div>
      <div className="input-group input-daterange" id="packDateRange">
        <input
          className="form-control"
          onBlur={onBlur('from')}
          onChange={onChange('from')}
          type="text"
          value={values.from}
        />
        <div className="input-group-prepend input-group-append">
          <div className="input-group-text">по</div>
        </div>
        <input
          className="form-control"
          onBlur={onBlur('till')}
          onChange={onChange('till')}
          type="text"
          value={values.till}
        />
      </div>
      <ErrorAlert className="mt-2">{error}</ErrorAlert>
    </div>
  );
};
