import { Machine } from './MachineTypes';

export const validateMachine = ({
  creating = false,
}: { creating?: boolean } = {}) => (values: Machine) => {
  const errors: { [key: string]: string } = {};
  if (!values.model_id) {
    errors.model_id = 'Нужно заполнить';
  }
  if (!values.city_id) {
    errors.city_id = 'Нужно заполнить';
  }
  if (!values.owner_id) {
    errors.owner_id = 'Нужно выбрать';
  }
  return errors;
};
