import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';

import { FormGroup } from './FormGroup';
import { inputError } from './errors';
import { parseNumber } from '../../utils/format';

type InputProps = {
  append?: JSX.Element;
  cols?: number;
  help?: string;
  label: string;
  labelCols?: number;
  name: string;
} & React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<
  InputProps & React.HTMLProps<HTMLInputElement>
> = ({ append, cols = 6, help, label, labelCols = 2, name, ...rest }) => (
  <Field name={name} parse={rest.type === 'number' ? parseNumber : undefined}>
    {({ input, meta }) => {
      const { invalid, error } = inputError(meta);
      return (
        <FormGroup
          cols={cols}
          error={error}
          id={name}
          label={label}
          labelCols={labelCols}
        >
          <div className="input-group">
            <input
              className={classnames('form-control', invalid && 'is-invalid')}
              id={name}
              {...input}
              {...rest}
            />
            {append && <div className="input-group-append">{append}</div>}
          </div>
          {error && <div className="invalid-feedback">{error}</div>}
          {help && <small className="form-text text-muted">{help}</small>}
        </FormGroup>
      );
    }}
  </Field>
);
