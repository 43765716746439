import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { api, catchToast, useAPI } from '../services/api';
import { CenterSpinner } from '../components/CenterSpinner';
import { ErrorAlert } from '../components/ErrorAlert';
import { MyMachineSettings } from './MachineTypes';

export const MySettings: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const { data, refreshing, error } = useAPI<MyMachineSettings>(
    `/my-machines/${match.params.id}/settings`
  );

  const [password, setPassword] = useState('');

  useEffect(() => {
    setPassword(`${data?.password.value || ''}`);
  }, [data]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const isValid = useMemo(() => {
    const parsed = parseInt(password);
    return (
      !Number.isNaN(parsed) &&
      parsed >= 1 &&
      parsed <= 59999 &&
      password === `${parsed}` &&
      parsed !== data?.password.value
    );
  }, [data, password]);

  const generate = useCallback(() => {
    setPassword((v) => {
      let next: string;
      do {
        next = `${Math.floor(Math.random() * 59999) + 1}`;
      } while (next === `${data?.password.value}` && v === next);
      return next;
    });
  }, [data]);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!isValid) {
        return;
      }
      api
        .put(`/my-machines/${match.params.id}/settings`, {
          password: parseInt(password),
        })
        .then(() => {
          toast.success('Пароль будет изменен в ближайшее время');
          history.push(`/machine${match.params.id}`);
        })
        .catch(catchToast);
    },
    [history, isValid, match.params.id, password]
  );

  return (
    <>
      <h2>Настройки</h2>

      {refreshing && <CenterSpinner />}
      <ErrorAlert>{error}</ErrorAlert>

      {data && (
        <form onSubmit={onSubmit}>
          <div className="form-group" style={{ margin: '2.5em 0 1.5em' }}>
            <label htmlFor="password">Пароль</label>

            <div className="input-group" style={{ maxWidth: 250 }}>
              <input
                className="form-control"
                id="password"
                maxLength={5}
                name="password"
                onChange={onChange}
                type="text"
                value={password}
              />
              <span className="input-group-append">
                <button
                  className="btn btn-secondary"
                  onClick={generate}
                  type="button"
                >
                  Генерировать
                </button>
              </span>
            </div>
            <p className="help-block">Введите число от 1 до 59&nbsp;999</p>
          </div>

          <button
            className="btn btn-primary mr-2"
            disabled={!isValid}
            type="submit"
          >
            Сохранить
          </button>

          <Link
            to={`/machine${match.params.id}`}
            className="btn btn-secondary mr-2"
          >
            Отмена
          </Link>

          <span
            className={classnames(
              'form-control-static',
              data.password.dirty && 'text-danger'
            )}
          >
            {data.password.dirty
              ? 'пароль сохраняется...'
              : 'пароль сохранён в машину'}
          </span>
        </form>
      )}
    </>
  );
};
