import React, { useMemo } from 'react';
import { FormRenderProps, FormSpy } from 'react-final-form';

import { byId } from '../utils/byId';
import { Checkbox } from '../components/forms/Checkbox';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { GenerateButton } from './GenerateButton';
import { Input } from '../components/forms/Input';
import { Machine as MachineType, PaymentProviders } from './MachineTypes';
import { Select, SelectOption } from '../components/forms/Select';
import { TextArea } from '../components/forms/TextArea';
import { useAPI } from '../services/api';

export const MachineForm: React.FC<FormRenderProps<MachineType>> = ({
  handleSubmit,
  submitting,
  values,
}) => {
  const cities = useAPI<SelectOption[]>('/cities/select');
  const models = useAPI<SelectOption[]>('/models/select');

  const users = useAPI<SelectOption[]>('/users/select');
  const { data: lastId } = useAPI<number | null>(
    values.id === undefined ? undefined : `/machines/${values.id}/last-id`
  );
  const usersById = useMemo(() => byId(users.data || []), [users]);

  return (
    <>
      <div className="form-group row">
        <div className="col-sm-10 offset-sm-2">
          <div>
            Создал: {usersById[values.creator_id]?.title}{' '}
            {values.date_created &&
              values.date_created.format('DD.MM.YYYY в HH:mm:ss')}
          </div>
          <div>
            Редактировал: {usersById[values.editor_id]?.title}{' '}
            {values.date_edited &&
              values.date_edited.format('DD.MM.YYYY в HH:mm:ss')}
          </div>
          <div>Внешний IP-адрес: {values.external_ip}</div>
          <div>Внутренний IP-адрес: {values.internal_ip}</div>
          <div>Версия ПО: {values.software_version}</div>
          {lastId !== undefined && (
            <div>Последний ID события: {lastId || '—'}</div>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <Select
          name="model_id"
          label="Модель"
          options={models.data || []}
          cols={2}
          emptyOption
        />
        <Input name="date_installed" label="Дата установки" cols={2} />
        <Select
          name="city_id"
          label="Город"
          cols={4}
          options={cities.data || []}
          emptyOption
        />
        <Select
          name="owner_id"
          label="Владелец"
          cols={4}
          options={users.data || []}
          emptyOption
        />
        <Input name="location" label="Расположение" cols={4} />
        <Input name="serial" label="Серийный номер" cols={2} />
        <Checkbox
          name="password_enabled"
          label="Управление паролем панели оператора"
        />
        <Input
          append={<GenerateButton name="password" type="60k" />}
          cols={3}
          label="Пароль панели оператора (1-60k)"
          name="password"
          type="text"
        />
        <Input
          append={<GenerateButton name="api_token" />}
          cols={10}
          label="Токен API"
          name="api_token"
          type="text"
        />
        <Input
          name="pack_cost"
          label="Стоимость упаковки"
          type="text"
          cols={2}
        />
        <Checkbox name="hidden" label="Скрыть из списка наблюдателя" />
        <TextArea label="Дополнительная информация" name="notes" rows={8} />
        <TextArea label="Мастер-коды" name="master_codes" rows={4} cols={4} />
        <Input
          name="heavy_bag_weight"
          label="Вес тяжелой сумки"
          type="text"
          cols={2}
        />
        <Input
          append={<GenerateButton name="code_salt" />}
          cols={10}
          label="Соль для штрихкода"
          name="code_salt"
          type="text"
        />
        <Select
          cols={2}
          emptyOption
          label="Провайдер чеков"
          name="payment_provider_id"
          options={PaymentProviders}
        />

        <FormSubmitContainer>
          <button
            className="btn btn-lg btn-primary"
            type="submit"
            disabled={submitting}
          >
            Сохранить
          </button>
        </FormSubmitContainer>
      </form>

      <FormSpy
        subscription={{ submitFailed: true }}
        onChange={() => {
          setTimeout(() => {
            const el = document.querySelector('.is-invalid');
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          });
        }}
      />
    </>
  );
};
