import React, { useCallback } from 'react';
import { useField } from 'react-final-form';
import { randomBase58Str } from '../utils/randomBase58Str';

interface GenerateButtonProps {
  name: string;
  type?: 'base58' | '60k';
}

export const GenerateButton: React.FC<GenerateButtonProps> = ({
  name,
  type = 'base58',
}) => {
  const {
    input: { onChange },
  } = useField(name);

  const onClick = useCallback(() => {
    const value =
      type === 'base58'
        ? randomBase58Str(64)
        : `${Math.floor(Math.random() * 59999) + 1}`;
    onChange({ target: { value } });
  }, [onChange, type]);

  return (
    <button type="button" className="btn btn-secondary" onClick={onClick}>
      Генерировать
    </button>
  );
};
