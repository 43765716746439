import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'react-final-form';

import { EntityHeader } from '../components/EntityHeader';
import { Machine as MachineType } from './MachineTypes';
import { MachineForm } from './MachineForm';
import { useItem } from '../services/useItem';
import { validateMachine } from './validateMachine';

export const Machine: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<MachineType>(match.params.id, '/machines', history);
  const { creating, found, item, save } = data;

  const saveFixed = useCallback(
    ({ payment_provider_id, ...v }: MachineType) => {
      return save({
        ...v,
        payment_provider_id:
          `${payment_provider_id}` === '0' ? null : payment_provider_id,
      });
    },
    [save]
  );

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={(i) => `Машина ${i.id || ''}`}
        listLabel="Машины"
        listUrl="/machines"
      />

      {found && (
        <Form
          initialValues={item}
          onSubmit={saveFixed}
          render={MachineForm}
          validate={validateMachine({ creating })}
        />
      )}
    </>
  );
};
