import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import { useAuth } from '../services/auth';
import { config } from '../config';

export const Layout: React.FC = ({ children }) => {
  const { admin, logout, user } = useAuth();

  return (
    <>
      <Navbar color="light" light expand="md">
        <div className="container">
          <NavbarBrand tag={Link} to="/">
            {config.app.name}
          </NavbarBrand>
          <Nav className="mr-auto" navbar>
            {admin && (
              <>
                <NavItem>
                  <NavLink tag={Link} to="/machines" exact>
                    Машины
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/payments">
                    Платежи
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {user && user.name}
              </DropdownToggle>
              <DropdownMenu right>
                {admin && (
                  <>
                    <DropdownItem tag={Link} to="/users">
                      Пользователи
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/models">
                      Модели
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/cities">
                      Города
                    </DropdownItem>
                  </>
                )}
                {admin && <DropdownItem divider />}
                <DropdownItem onClick={logout}>Выход</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </Navbar>
      <div className="container py-3">{children}</div>
    </>
  );
};
