import { roles, User } from './UserTypes';

const reEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const validateUser = ({
  creating = false,
}: { creating?: boolean } = {}) => (values: User) => {
  const errors: { [key: string]: string } = {};
  if (!values.full_name) {
    errors.full_name = 'Нужно заполнить';
  }
  if (!values.login) {
    errors.login = 'Нужно заполнить';
  }
  if (!values.role) {
    errors.role = 'Нужно выбрать';
  } else if (typeof roles[values.role] === 'undefined') {
    errors.role = 'Роль не из списка';
  }
  if (values.email && !reEmail.test(values.email)) {
    errors.email = 'Неверный формат электронной почты';
  }
  if (creating) {
    if (!values.password) {
      errors.password = 'Нужно заполнить';
    } else if (values.password.length < 6) {
      errors.password = 'Пароль короче 6 символов';
    }
  }
  return errors;
};
