import React from 'react';
import { Moment } from 'moment';

import './LastEdited.css';

interface LastEditedProps {
  item: {
    date_created: Moment | null;
    date_edited: Moment | null;
  };
}

export const LastEdited: React.FC<LastEditedProps> = ({ item }) => {
  const date = item.date_edited || item.date_created;
  return (
    <td
      className="last-edited"
      title={date ? date.format('DD.MM.YYYY HH:mm:ss') : undefined}
    >
      {date && date.fromNow()}
    </td>
  );
};
