import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CenterSpinner } from './CenterSpinner';
import { config } from '../config';
import { ErrorAlert } from './ErrorAlert';
import { Layout } from './Layout';
import { Login } from './Login';
import { MyMachine } from '../my/MyMachine';
import { MyMachines } from '../my/MyMachines';
import { NoMatch } from './NoMatch';
import { useAuth } from '../services/auth';
import { User } from '../users/User';
import { Users } from '../users/Users';
import { Machines } from '../machines/Machines';
import { Machine } from '../machines/Machine';
import { Payments } from '../payments/Payments';
import { MySettings } from '../my/MySettings';

export const Routes: React.FC = () => {
  const {
    admin,
    error,
    loaded,
    login,
    loginError,
    loginRefreshing,
    user,
  } = useAuth();

  if (!loaded) {
    return <CenterSpinner />;
  }

  if (error) {
    return (
      <div className="container-fluid main-container my-3">
        <ErrorAlert>{error}</ErrorAlert>
      </div>
    );
  }

  if (!user) {
    return (
      <Login
        error={loginError}
        onSubmit={login}
        refreshing={loginRefreshing}
        title={config.app.name}
      />
    );
  }

  return (
    <Layout>
      <ToastContainer position="bottom-right" />
      <Switch>
        {admin && <Route path="/users" exact component={Users} />}
        {admin && <Route path="/users/:id" exact component={User} />}

        <Route path="/(all)?" exact component={MyMachines} />
        <Route path="/machine:id(\d+)" exact component={MyMachine} />
        <Route path="/machine:id(\d+)/settings" exact component={MySettings} />

        {admin && <Route path="/machines" exact component={Machines} />}
        {admin && <Route path="/machines/:id" exact component={Machine} />}

        {admin && <Route path="/payments" exact component={Payments} />}

        <Route component={NoMatch} />
      </Switch>
    </Layout>
  );
};
