import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form } from 'react-final-form';

import { EntityHeader } from '../components/EntityHeader';
import { useItem } from '../services/useItem';
import { User as UserType } from './UserTypes';
import { UserForm } from './UserForm';
import { validateUser } from './validateUser';

export const User: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<UserType>(match.params.id, '/users', history);
  const { creating, found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={() => 'Пользователь'}
        listLabel="Пользователи"
        listUrl="/users"
      />

      {found && (
        <Form
          initialValues={item}
          onSubmit={save}
          render={UserForm}
          validate={validateUser({ creating })}
        />
      )}
    </>
  );
};
