import React from 'react';

interface FormGroupProps {
  cols?: number;
  error?: string;
  id?: string;
  label?: string;
  labelCols?: number;
}

export const FormGroup: React.FC<FormGroupProps> = ({
  children,
  cols = 10,
  id,
  label,
  labelCols = 2,
}) => (
  <div className="form-group row">
    <label className={`col-sm-${labelCols} col-form-label`} htmlFor={id}>
      {label}
    </label>
    <div className={`col-sm-${cols}`}>{children}</div>
  </div>
);
