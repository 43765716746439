import React, { useCallback } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';

import { CenterSpinner } from '../components/CenterSpinner';
import { ErrorAlert } from '../components/ErrorAlert';
import { MyMachinesList } from './MachineTypes';
import { useAPI } from '../services/api';

export const MyMachines: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const all = location.pathname === '/all';

  const { data, error, refreshing } = useAPI<MyMachinesList>(
    `/my-machines${all ? '?all=1' : ''}`
  );

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const link = e.currentTarget.querySelector('a');
      if (link) {
        const href = link.getAttribute('href');
        if (href) {
          history.push(href);
        }
      }
    },
    [history]
  );

  return (
    <>
      <div className="d-flex flex-row mb-2">
        <h2>
          Машины
          {data && <small className="ml-2">{data.machines.length}</small>}
        </h2>
        {data?.canToggleAll && (
          <ul className="nav nav-pills ml-auto">
            <li role="presentation" className="nav-item">
              <NavLink exact to="/all" className="nav-link">
                Все
              </NavLink>
            </li>
            <li role="presentation" className="nav-item">
              <NavLink exact to="/" className="nav-link">
                Актуальные
              </NavLink>
            </li>
          </ul>
        )}
      </div>

      {refreshing && <CenterSpinner />}
      <ErrorAlert>{error}</ErrorAlert>

      {data && (
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th>Машина</th>
              <th>Расположение</th>
              <th>Количество упаковок</th>
            </tr>
          </thead>
          <tbody>
            {data.machines.map((i) => (
              <tr key={i.id} onClick={onRowClick}>
                <td>
                  <div>
                    <Link to={`/machine${i.id}`}>{i.serial}</Link>{' '}
                    {i.online.isActive ? (
                      <FaCheck className="text-success" />
                    ) : (
                      <FaTimes className="text-danger" />
                    )}
                  </div>
                  <div>{i.model}</div>
                </td>
                <td>
                  <div>{i.location}</div>
                  <div>{i.city}</div>
                </td>
                <td>
                  <div>Сегодня: {i.packs.today}</div>
                  <div>Вчера: {i.packs.yesterday}</div>
                  <div>За неделю: {i.packs.week}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
