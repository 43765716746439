import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { LastEdited } from '../components/LastEdited';
import { useItems } from '../services/useItems';
import { MachineListItem } from './MachineTypes';

export const Machines: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<MachineListItem>('/machines', history, location);
  const { found, data, onPageClick, onRowClick } = list;

  return (
    <>
      <EntityListHeader
        data={list}
        title="Машины"
        createUrl="/machines/create"
        onPageClick={onPageClick}
      />

      {found && (
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Модель</th>
              <th>Город</th>
              <th>Место</th>
              <th>Владелец</th>
              <th>Последняя правка</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr
                key={i.id}
                onClick={onRowClick}
                style={i.hidden ? { opacity: 0.5 } : undefined}
              >
                <td className="text-primary text-right tab-nums">
                  <Link to={`/machines/${i.id}`}>{i.id}</Link>
                </td>
                <td>{i.model}</td>
                <td>{i.city}</td>
                <td>{i.location}</td>
                <td>{i.owner}</td>
                <LastEdited item={{ ...i, date_created: null }} />
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
