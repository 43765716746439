import { Moment } from 'moment';

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  Supervisor = 'supervisor',
}

export interface User {
  id: number;
  deleted: number;
  creator_id: number;
  editor_id: number;
  date_created: Moment;
  date_edited: Moment;
  login: string;
  full_name: string;
  password: string;
  email: string;
  status: number;
  role: UserRole;
  department: string;
  comment: string;
  timezone: string | null;
}

export const roles: Record<UserRole, string> = {
  [UserRole.Admin]: 'Администратор',
  [UserRole.Editor]: 'Пользователь',
  [UserRole.Supervisor]: 'Наблюдатель',
};
