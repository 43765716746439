import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import {
  FaBarcode,
  FaChevronLeft,
  FaChevronRight,
  FaExclamationCircle,
} from 'react-icons/fa';

import { AllPackRow } from './MachineTypes';
import { dateStr, hourStr, wordAtNumber } from '../utils/format';
import { ErrorAlert } from '../components/ErrorAlert';
import { useAPI } from '../services/api';

interface HorPackModalProps {
  date: Date;
  hours: number[];
  machineId: number;
  onDateChange: React.Dispatch<React.SetStateAction<Date | undefined>>;
  suspicious?: boolean;
}

export const HourPackModal: React.FC<HorPackModalProps> = ({
  date,
  hours,
  machineId,
  onDateChange,
  suspicious,
}) => {
  const close = useCallback(() => onDateChange(undefined), [onDateChange]);

  const { data, error, refreshing } = useAPI<{ data: AllPackRow[] }>(
    `/my-machines/${machineId}/hour-packs?date=${date.toJSON()}`
  );

  const hour = date.getHours();
  const isFirstHour = useMemo(
    () => hours.every((h) => h >= hour),
    [hour, hours]
  );
  const isLastHour = useMemo(
    () => hours.every((h) => h <= hour),
    [hour, hours]
  );

  const setHour = useCallback(
    (h: number) => {
      onDateChange((v) => {
        if (!v) {
          return v;
        }
        const d = new Date(v.getTime());
        d.setHours(h);
        return d;
      });
    },
    [onDateChange]
  );

  const prev = useCallback(() => {
    const hrs = hours.filter((h) => h < hour);
    if (hrs.length === 0) {
      return;
    }
    hrs.sort((a, b) => a - b);
    setHour(hrs[hrs.length - 1]);
  }, [hour, hours, setHour]);

  const next = useCallback(() => {
    const hrs = hours.filter((h) => h > hour);
    if (hrs.length === 0) {
      return;
    }
    hrs.sort((a, b) => a - b);
    setHour(hrs[0]);
  }, [hour, hours, setHour]);

  useEffect(() => {
    const l = (e: KeyboardEvent) => {
      if (e.code === 'ArrowLeft') {
        prev();
      } else if (e.code === 'ArrowRight') {
        next();
      }
    };
    window.addEventListener('keydown', l);
    return () => {
      window.removeEventListener('keydown', l);
    };
  }, [prev, next]);

  const rows: JSX.Element[] = [];

  let packNumber = 1;
  (data?.data || []).forEach((i, n) => {
    if (i.pack) {
      rows.push(
        <tr>
          <td>
            {packNumber++}
            {i.pack.subtype === 0 && (
              <FaExclamationCircle
                className="ml-2"
                title="Сканирование штрихкодов отключено в настройках"
              />
            )}
          </td>
          <td>{i.date.format('HH:mm:ss')}</td>
          <td>
            {i.pack.weight !== undefined && (
              <>
                {i.pack.weight === 9999
                  ? '+∞'
                  : i.pack.weight === -9999
                  ? '−∞'
                  : i.pack.weight.toFixed(1)}{' '}
                кг
              </>
            )}
          </td>
        </tr>
      );

      i.pack.events.forEach((e, tn) => {
        rows.push(
          <tr
            key={e.id}
            className={suspicious && tn > 2 ? 'text-danger' : undefined}
          >
            <td></td>
            <td>{e.date.format('HH:mm:ss')}</td>
            <td>
              {e.value}{' '}
              {e.value !== undefined &&
                wordAtNumber(e.value, 'оборот', 'оборота', 'оборотов')}
            </td>
          </tr>
        );
      });
    }
    if (i.scan) {
      rows.push(
        <tr className={i.scan.isMasterCode ? 'text-danger' : undefined}>
          <td>
            <FaBarcode /> {i.scan.packCount}
            {i.scan.totalPacks !== undefined && `→ ${i.scan.totalPacks}`}
          </td>
          <td>{i.date.format('HH:mm:ss')}</td>
          <td>{i.scan.barcode}</td>
        </tr>
      );
    }
  });

  return (
    <Modal isOpen toggle={close} size="lg">
      <ModalHeader toggle={close}>
        <div className="d-flex flex-row">
          <button
            className="btn btn-sm btn-secondary"
            disabled={isFirstHour}
            onClick={prev}
            type="button"
          >
            <FaChevronLeft />
          </button>
          <div className="mx-2">
            Упаковки за {dateStr(date)} ({hourStr(hour)})
          </div>
          <button
            className="btn btn-sm btn-secondary"
            disabled={isLastHour}
            onClick={next}
            type="button"
          >
            <FaChevronRight />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        {!data && refreshing && <Spinner color="secondary" />}
        <ErrorAlert>{error}</ErrorAlert>

        {data && (
          <table className="table table-sm">
            <thead>
              <tr>
                <th>
                  №
                  {refreshing && (
                    <Spinner className="ml-2" color="secondary" size="sm" />
                  )}
                </th>
                <th>Время</th>
                <th>Данные</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={close}>
          Закрыть
        </button>
      </ModalFooter>
    </Modal>
  );
};
