import { useMemo } from 'react';
import { History, Location } from 'history';
import qs, { ParsedQs } from 'qs';

type SetSearch = React.Dispatch<React.SetStateAction<ParsedQs>>;

export const useSearch = ({
  history,
  location,
}: {
  history: History;
  location: Location;
}) => {
  const search = useMemo<ParsedQs>(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const setSearch = useMemo<SetSearch>(() => {
    const prev = qs.parse(location.search, { ignoreQueryPrefix: true });
    return (v) => {
      const next = typeof v === 'function' ? v(prev) : v;
      const s = qs.stringify(next, { addQueryPrefix: true });
      if (s !== location.search) {
        history.push({
          pathname: location.pathname,
          search: s,
        });
      }
    };
  }, [history, location.search, location.pathname]);

  return [search, setSearch] as [ParsedQs, SetSearch];
};
