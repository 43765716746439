import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { LastEdited } from '../components/LastEdited';
import { Paginator } from '../components/Paginator';
import { useItems } from '../services/useItems';
import { User as UserType, roles } from './UserTypes';

export const Users: React.FC<RouteComponentProps> = ({ history, location }) => {
  const list = useItems<UserType>('/users', history, location);
  const { found, data, onPageClick, onRowClick, page, pageCount } = list;

  return (
    <>
      <EntityListHeader
        data={list}
        title="Пользователи"
        createUrl="/users/create"
        onPageClick={onPageClick}
      />

      {found && (
        <div className="table-responsive">
          <table className="table table-hover table-list">
            <thead>
              <tr>
                <th>Полное имя</th>
                <th>Роль</th>
                <th>Статус</th>
                <th>Последняя правка</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id} onClick={onRowClick}>
                  <td>
                    <Link to={`/users/${user.id}`}>{user.full_name}</Link>
                  </td>
                  <td>{user.role && roles[user.role]}</td>
                  <td>{user.status === 1 ? 'Выкл.' : 'Вкл.'}</td>
                  <LastEdited item={user} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="d-flex flex-row justify-content-end">
        {pageCount > 1 && (
          <Paginator
            page={page}
            onPageClick={onPageClick}
            pageCount={pageCount}
          />
        )}
      </div>
    </>
  );
};
