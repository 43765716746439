import React from 'react';

import { AuthContext, useAuthData } from '../services/auth';

export const AuthProvider: React.FC = ({ children }) => {
  const authData = useAuthData();
  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
};
