import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import BounceLoader from 'react-spinners/BounceLoader';

import './CenterSpinner.css';

export enum SpinnerType {
  Beat = 'beat',
  Bounce = 'bounce',
}

interface CenterSpinnerProps {
  type?: SpinnerType;
  delay?: number;
}

export const CenterSpinner: React.FC<CenterSpinnerProps> = ({
  type = SpinnerType.Beat,
  delay = 100,
}) => {
  const [visible, setVisible] = useState(delay === 0);

  useEffect(() => {
    const t = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [delay]);

  if (!visible) {
    return null;
  }

  return (
    <div className="center-spinner">
      {type === SpinnerType.Beat && <BeatLoader size={12} color={'#46b8da'} />}
      {type === SpinnerType.Bounce && (
        <BounceLoader size={48} color={'#46b8da'} />
      )}
    </div>
  );
};
