import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';

import { FormGroup } from './FormGroup';
import { inputError } from './errors';

export interface SelectOption {
  id: number;
  title: string;
}

type SelectProps = {
  cols?: number;
  emptyOption?: boolean;
  label?: string;
  name: string;
  options:
    | {
        id: number | string;
        title: string;
      }[]
    | { [key: string]: string };
} & React.HTMLProps<HTMLSelectElement>;

export const Select: React.FC<SelectProps> = ({
  cols = 10,
  emptyOption,
  label,
  name,
  options,
  ...rest
}) => (
  <Field name={name} type="select">
    {({ input, meta }) => {
      const { error, invalid } = inputError(meta);
      return (
        <FormGroup cols={cols} label={label} id={name}>
          <select
            className={classnames('form-control', invalid && 'is-invalid')}
            id={name}
            {...input}
            {...rest}
          >
            {emptyOption && <option></option>}
            {Array.isArray(options)
              ? options.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.title}
                  </option>
                ))
              : Object.keys(options).map((id) => (
                  <option key={id} value={id}>
                    {options[id]}
                  </option>
                ))}
          </select>
          {error && <div className="invalid-feedback">{error}</div>}
        </FormGroup>
      );
    }}
  </Field>
);
