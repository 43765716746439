import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { ParsedQs } from 'qs';

interface PackFiltersProps {
  search: ParsedQs;
  setSearch: React.Dispatch<React.SetStateAction<ParsedQs>>;
}

export const PackFilters: React.FC<PackFiltersProps> = ({
  search,
  setSearch,
}) => {
  const turns = search.turns === '1';
  const bags = search.bags === '1';
  const suspicious = search.suspicious === '1';

  const [local, setLocal] = useState({
    turns,
    bags,
    suspicious,
  });

  useEffect(() => {
    setLocal((v) => ({ ...v, turns }));
  }, [turns]);

  useEffect(() => {
    setLocal((v) => ({ ...v, bags }));
  }, [bags]);

  useEffect(() => {
    setLocal((v) => ({ ...v, suspicious }));
  }, [suspicious]);

  const onChange = useCallback(
    (name: 'turns' | 'bags' | 'suspicious') => (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      setLocal((v) => ({ ...v, [name]: e.target.checked }));
    },
    []
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setSearch((v) => ({
        ...v,
        turns: local.turns ? '1' : undefined,
        bags: local.bags ? '1' : undefined,
        suspicious: local.suspicious ? '1' : undefined,
      }));
    },
    [local, setSearch]
  );

  return (
    <form className="my-3" onSubmit={onSubmit}>
      <input type="hidden" name="date" value="2021-02-21" />
      <div className="checkbox">
        <label className="form-label">
          <input
            checked={local.turns}
            name="turns"
            onChange={onChange('turns')}
            type="checkbox"
            value="1"
          />{' '}
          Только с оборотами
        </label>
      </div>
      <div className="checkbox">
        <label className="form-label">
          <input
            checked={local.bags}
            name="bags"
            onChange={onChange('bags')}
            type="checkbox"
            value="1"
          />{' '}
          Тяжелее 2 кг
        </label>
      </div>
      <div className="checkbox">
        <label className="form-label">
          <input
            checked={local.suspicious}
            name="suspicious"
            onChange={onChange('suspicious')}
            type="checkbox"
            value="1"
          />{' '}
          Выделить подозрительные
        </label>
      </div>
      <button type="submit" className="btn btn-primary">
        Применить
      </button>
    </form>
  );
};
