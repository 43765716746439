import React, { useCallback } from 'react';
import { Moment } from 'moment';
import Datetime from 'react-datetime';

import './Datepicker.css';

interface DatepickerProps {
  value: Date;
  onChange: (d: Moment) => void;
}

export const Datepicker: React.FC<DatepickerProps> = ({ value, onChange }) => {
  const now = new Date();

  const onDateChange = useCallback(
    (v: Moment | string) => {
      if (typeof v !== 'string') {
        onChange(v);
      }
    },
    [onChange]
  );

  return (
    <Datetime
      input={false}
      value={value}
      onChange={onDateChange}
      timeFormat={false}
      isValidDate={(v: Moment) => !v.isAfter(now, 'day')}
      renderDay={({ className, ...props }, currentDate: Moment) => (
        <td
          className={`${className} ${
            currentDate.isAfter(now, 'day') ? 'rdtDayDisabled' : ''
          }`}
          {...props}
        >
          {currentDate.date()}
        </td>
      )}
    />
  );
};
