import { useState, useEffect } from 'react';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const minWidth: Record<Breakpoint, number> = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};

const breakpoints = Object.keys(minWidth) as Breakpoint[];

export const useBreakpoint = (): string => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const listener = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return breakpoints.find((bp) => minWidth[bp as Breakpoint] < width) || 'xs';
};
